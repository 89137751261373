<div class="flex items-center overflow-hidden gap-3 w-full" [ngClass]="{
  'flex-col': vertical
}">
  <ng-content select="[leading]"></ng-content>
  <div class="flex flex-col flex-1 gap-1">
    <div class="flex justify-between w-full gap-1">
      <p class="w-full" [ngClass]="{
        'font-medium': size === Size.MEDIUM,
        'text-left': !vertical,
        'text-center': vertical
      }">
        <ng-content select="[title]"></ng-content>
      </p>
      <p class="text-sm text-tertiary">
        <ng-content select="[date]"></ng-content>
      </p>
    </div>
    <p class="leading-tight text-sm text-secondary" [ngClass]="{
      'text-left': !vertical,
      'text-center': vertical
    }">
      <ng-content select="[subtitle]"></ng-content>
    </p>
  </div>
  <ng-content select="[trailing]"></ng-content>
</div>