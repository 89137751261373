<tui-elastic-container>
  @if (control.invalid && (control.dirty || control.touched)) {
    <p
      class="mt-2 text-red-700 text-sm leading-4" 
      data-testid="message"
    >
      @if (control.errors.required) {
        Ce champ est requis.
      }
      @if (control.errors.maxlength) {
        Vous ne pouvez pas entrer plus de {{control.errors.maxlength.requiredLength}} caractères.
      }
      @if (control.errors.minlength) {
        Veuillez entrer au moins {{control.errors.minlength.requiredLength}} caractères.
      }
      @if (control.errors.email) {
        Veuillez entrer une adresse email valide.
      }
      @if (control.errors.password) {
        {{ configuration.password.message }}
      }
      @if (control.errors.phoneNumber) {
        {{ configuration.phoneNumber.message }}
      }
      @if (control.errors.min) {
        {{ configuration.min.message }}
      }
      @if (control.errors.max) {
        {{ configuration.max.message }}
      }
    </p>
  }
</tui-elastic-container>