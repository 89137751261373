<div class="flex flex-col gap-4">
  @if (title) {
    <pxc-title
      [title]="title"
      [icon]="icon"
      titleSize="tiny"
      data-testid="title"
    ></pxc-title>
  }
  <div class="grid md:grid-cols-2 gap-3 mx-1">
    <ng-content></ng-content>
  </div>
</div>
