<div class="flex items-center gap-3 my-2">
  @if (icon) {
    <tui-icon  [icon]="icon" data-testid="icon"></tui-icon>
  }
  <div>
    @switch (titleSize) {
      @case (Size.LARGE) {
        <h1>{{ title }}</h1>
      }
      @case (Size.MEDIUM) {
        <h2>{{ title }}</h2>
      }
      @case (Size.SMALL) {
        <h5>{{ title }}</h5>
      }
      @default {
        <h6>{{ title }}</h6>
      }
    }
    @if (subtitle) {
      <p class="text-secondary text-sm">{{ subtitle }}</p>
    }
  </div>

  @if (counter) {
    <tui-badge       
      data-testid="counter" 
      class="!font-bold mt-1" size="l"
    >
      {{counter}}{{ maxCount ? '/'+maxCount : ''}}
    </tui-badge>
  }
</div>