<div
  class="flex justify-center items-center relative"
>
  @if (max > itemsCount) {
    <button
      tuiIconButton
      appearance="flat"
      class="!hidden lg:!block !absolute -translate-y-1/2 top-1/2 z-10 -left-9"
      [ngClass]="{
        'invisible': index === 0, 
        '!hidden': hideControlsOnMobile
      }"
      (click)="previous()"
      data-testid="previous"
      iconStart="@tui.chevron-left"
      size="s"
    ></button>
  }

  <ng-content></ng-content>

  @if (max > itemsCount) {
    <button
      tuiIconButton
      appearance="flat"
      class="!hidden lg:!block !absolute -translate-y-1/2 top-1/2 z-10 -right-9"
      [ngClass]="{
        'invisible': index + itemsCount > max - 1, 
        '!hidden': hideControlsOnMobile
      }"
      iconStart="@tui.chevron-right"
      (click)="next()"
      data-testid="next"
      size="s"
    ></button>
  }
</div>